<template>
  <div class="page-editor page-template-editor">
    <div class="editor-wrap">
      <div class="graphproject-wrap">
        <article
          id="graphproject-canvas"
          class="graphproject-canvas"
          :style="{
            width: this.canvas.width + 'px',
            height: this.canvas.height + 'px',
            overflow: 'hidden',
            position: 'relative',
            background: `${this.canvas.background} ${this.canvas.backgroundPosition} / ${this.canvas.backgroundSize} ${this.canvas.backgroundRepeat}`,
          }"
        >
          <canvas-elements :initialElements="initialElements"></canvas-elements>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "@/components/common/draggable-moveable/draggable-moveable.component.vue";
import draggableResizable from "@/components/common/draggable-resizable/draggable-resizable.component.vue";
import canvasElements from "../editor/canvas-elements/canvas-elements.component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    draggable,
    "draggable-resizable": draggableResizable,
    "canvas-elements": canvasElements,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      initialElements: [],
      overlayColor: "",
    };
  },
  watch: {
    "$route.params.projectId": {
      handler: function() {
        this.projectId = this.$route.params.projectId;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted: function() {
    this.resetEverything()
      .then(() => {
        this.mountProject();
      })
      .catch((e) => {
        console.error(e);
      });
  },
  computed: {
    ...mapGetters("elementsModule", ["canvas"]),
    ...mapActions("projectsModule", ["getProjectById", "resetCurrentProject"]),
    ...mapActions("elementsModule", ["resetEditor"]),
  },
  methods: {
    resetEverything() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("elementsModule/resetEditor")
          .then(() => {
            this.$store.dispatch("projectsModule/resetCurrentProject");
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    mountProject: function() {
      this.getExistingProjectById(this.projectId);
    },
    async getExistingProjectById(id) {
      try {
        const project = await this.$store.dispatch(
          "projectsModule/getProjectById",
          {
            id,
          }
        );
        this.initialElements = project.elements;

        await this.$store.dispatch(
          "elementsModule/changeCanvasProperties",
          project.canvas_properties
        );
      } catch (e) {
        return e;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
